import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isoWeek from 'dayjs/plugin/isoWeek'

import I18n from '../../i18n'
import usePlot from '../../lib/use-plot'
import useFilter from '../../lib/use-filter'
import WeatherIcon from '../WeatherIcon'
import useLocation from '../../lib/use-location'
import Hide from '../Hide'
import DatetimeHelpers from '../../lib/helpers/datetime-helpers'
import useAuthorization from '../../lib/use-authorization'

function DateTimeReading({ point, multiComparisonModeActive }) {
  const [filter] = useFilter()
  const { plot } = usePlot()
  const { locations } = useLocation()
  const { location, compareLocation } = useLocation()
  const timeRangeFilterActive =
    filter.viewType === 'compare' && filter.compareType === 'timerange'

  const comparisonTitle = useMemo(() => {
    if (filter.compareType === 'location') {
      const compareLocation = locations[filter.compareWith]
      return `${compareLocation.name}, ${compareLocation.city}`
    }
  }, [filter, locations])

  if (plot.length < 1) return null

  return (
    <ContentWrapper centerVertically={multiComparisonModeActive}>
      <Row>
        {point ? (
          <CurrentReading
            resolution={filter.resolution}
            timestamp={point.timestamp}
            weatherCondition={point.weatherCondition}
            minTemperature={point.minTemperature}
            temperature={point.temperature}
          />
        ) : (
          <InitialReading
            from={filter.from}
            to={filter.to}
            location={timeRangeFilterActive ? compareLocation : location}
          />
        )}
      </Row>
      {filter.viewType === 'compare' &&
        filter.compareType !== 'average' &&
        filter.compareType !== 'multiLocation' && (
          <>
            {filter.compareType === 'location' && (
              <Hide when={bp => bp.tablet}>
                <CompareLocation>{comparisonTitle}</CompareLocation>
              </Hide>
            )}
            <Row compare>
              {point ? (
                <CurrentReading
                  resolution={filter.resolution}
                  timestamp={point.compareTimestamp}
                  weatherCondition={point.compareWeatherCondition}
                  temperature={point.compareTemperature}
                />
              ) : (
                <InitialReading
                  from={filter.compareFrom}
                  to={filter.compareTo}
                  location={location}
                />
              )}
            </Row>
          </>
        )}
    </ContentWrapper>
  )
}

DateTimeReading.propTypes = {
  point: PropTypes.shape({
    timestamp: PropTypes.string.isRequired,
    weatherCondition: PropTypes.string,
    minTemperature: PropTypes.number,
    temperature: PropTypes.number,
    compareTimestamp: PropTypes.string,
    compareWeatherCondition: PropTypes.string,
    compareTemperature: PropTypes.number
  }),
  multiComparisonModeActive: PropTypes.bool
}

function InitialReading({ from, to, location }) {
  const { permissions } = useAuthorization()
  return (
    <>
      <Item>
        {I18n.helpers.dateRange(from, to, {
          format: 'date.formats.long_weekday'
        })}
      </Item>
      {location && permissions.accessLocationWeather && (
        <Item>
          {location.metadata.weatherCondition && (
            <WeatherIcon
              type={location.metadata.weatherCondition}
              css={{ marginTop: '-0.25rem' }}
            />
          )}
          {location.metadata.minTemperature != null && (
            <div>{Math.round(location.metadata.minTemperature)}°C –&nbsp;</div>
          )}
          {location.metadata.temperature != null && (
            <div>{Math.round(location.metadata.temperature)}°C</div>
          )}
        </Item>
      )}
    </>
  )
}

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isoWeek)

InitialReading.propTypes = {
  from: PropTypes.instanceOf(Date).isRequired,
  to: PropTypes.instanceOf(Date).isRequired,
  location: PropTypes.object.isRequired
}

function CurrentReading({
  resolution,
  timestamp,
  weatherCondition,
  minTemperature,
  temperature
}) {
  // dayjs doesn't parse the timezone from the timestamp. This is a workaround to get it.
  const from = timestamp
  const to = dayjs(timestamp)
    .tz(DatetimeHelpers.zoneFromTimestamp(timestamp)) // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    .add(1, 'hour')
    .format('YYYY-MM-DDTHH:mm:ssZ')

  const { permissions } = useAuthorization()

  return (
    <>
      <Item>
        {resolution === 'week' &&
          `${I18n.t('location.timerange.week.general')} ${dayjs(
            timestamp
          ).isoWeek()}\u2009\u2013\u2009`}
        {I18n.l('date.formats.long_weekday', timestamp)}
      </Item>
      {resolution === 'hour' && from && (
        <Item>{I18n.helpers.hourRange(from, to)}</Item>
      )}

      {permissions.accessLocationWeather && (
        <Item>
          {weatherCondition && (
            <WeatherIcon
              type={weatherCondition}
              css={{ marginTop: '-0.25rem' }}
            />
          )}
          {minTemperature != null && (
            <div>{Math.round(minTemperature)}°C –&nbsp;</div>
          )}
          {temperature != null && <div>{Math.round(temperature)}°C</div>}
        </Item>
      )}
    </>
  )
}

CurrentReading.propTypes = {
  resolution: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  weatherCondition: PropTypes.string,
  minTemperature: PropTypes.number,
  temperature: PropTypes.number
}

const Row = styled.ul(props => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: props.compare ? '0.4rem' : '0.2rem',
  fontSize: props.compare ? '0.75rem' : '1rem',
  color: props.compare
    ? props.theme.chroma.accent.css()
    : props.theme.chroma.base.css(),

  svg: {
    width: props.compare ? '2rem' : '3rem',
    height: props.compare ? '1rem' : '1.5rem',
    marginTop: props.compare ? '-0.1rem' : '-0.25rem',
    marginLeft: props.compare ? '0.4rem' : 0
  },

  [props.theme.breakpoints.desktop]: {
    fontSize: props.compare ? '0.75rem' : '0.9rem',
    svg: {
      width: '2rem',
      height: '1rem',
      marginTop: '-0.1rem',
      marginLeft: 0
    }
  },

  [props.theme.breakpoints.tablet]: {
    fontSize: '0.75rem',
    display: props.compare ? 'none' : 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    svg: {
      width: '1rem',
      marginRight: '0.4rem'
    }
  }
}))

const Item = styled.li(props => ({
  display: 'flex',
  fontWeight: 700,
  minWidth: '8rem',
  margin: '0 1rem 0 1rem',
  whiteSpace: 'normal',

  [props.theme.breakpoints.desktop]: {
    minWidth: '8rem'
  },

  [props.theme.breakpoints.tablet]: {
    minWidth: 'initial',
    marginTop: '0.2rem'
  },

  [props.theme.breakpoints.phoneUp]: {
    minWidth: 0,
    marginTop: '0.2rem'
  }
}))

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: ${props =>
    props.centerVertically ? 'center' : 'flex-start'};
`

const CompareLocation = styled.div`
  color: ${props => props.theme.chroma.accent.css()};
  margin: 0.5rem 1rem 0;
  font-size: 1rem;
  font-weight: bold;
`

export default DateTimeReading
