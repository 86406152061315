import React from 'react'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import flatten from 'lodash/flatten'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'
import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import useFilter from './use-filter'
import useLocation from './use-location'
import useZoneDetails from './use-zone-details'
import { transformLocationsToLineChartData } from './transformers/location-transformers'
import PlotHelpers from './helpers/plot-helpers'
import DatetimeHelpers from './helpers/datetime-helpers'
import useAuthorization from './use-authorization'

dayjs.extend(utc)
dayjs.extend(timezone)

const context = React.createContext()

export default function usePlot() {
  return React.useContext(context)
}

export function PlotProvider({ ...rest }) {
  const fallbackTransformerResult = React.useRef({ plot: [], sums: {} })
  const [filter] = useFilter()
  const { permissions } = useAuthorization()
  const {
    location,
    compareLocation,
    compareLocations,
    averagesPerDay,
    averagesPerHour
  } = useLocation()

  const { showZoneDetails } = useZoneDetails()

  let measurements = location.measurements

  measurements = PlotHelpers.filterMeasurementsBeforeEarliestAccessibleDate(
    measurements,
    permissions.earliestAccessibleDate
  )

  // Ugh, why is daylight saving time still a thing? We need to handle it here.
  // Due to DST, the measurements array of the main or compare location could
  // be different, but we want to merge them into one array.
  // We take the main measurements as source of truth and add/remove an hour to
  // the compare measurements, so we can safely merge them.
  const unsafeCompareMeasurements = compareLocation.measurements
  const compareMeasurements = React.useMemo(() => {
    if (!unsafeCompareMeasurements || !measurements) {
      return unsafeCompareMeasurements
    }

    // compare measurements are in DST. add additional hour to compare measurements
    if (unsafeCompareMeasurements.length + 1 === measurements.length) {
      return PlotHelpers.addAdditionalHourToMeasurements(
        measurements,
        unsafeCompareMeasurements
      )
    }

    // main measurements are in DST. remove additional hour from compare measurements
    if (measurements.length + 1 === unsafeCompareMeasurements.length) {
      return PlotHelpers.removeAdditionalHourFromMeasurements(
        measurements,
        unsafeCompareMeasurements
      )
    }

    return unsafeCompareMeasurements
  }, [unsafeCompareMeasurements, measurements])

  // combine measurements from main location and compare location to one object
  const transformerResult = React.useMemo(() => {
    if (!measurements || !compareMeasurements) return { plot: [], sums: {} }

    const sums = {
      pedestriansCount: 0,
      comparePedestriansCount: 0,
      details: { adult: 0, child: 0, ltr: 0, rtl: 0 },
      zones: []
    }
    const zones = []

    const plot = measurements.map((measurement, i) => {
      sums.pedestriansCount += measurement.pedestriansCount

      const mainDate = dayjs(measurement.timestamp).tz(
        DatetimeHelpers.zoneFromTimestamp(measurement.timestamp)
      )
      const compareMeasurement = compareMeasurements[i]
      const point = {
        timestamp: measurement.timestamp,
        pedestriansCount: measurement.pedestriansCount,
        comparePedestriansCount: undefined,
        compareWeatherCondition: undefined,
        detailsPedestriansCount: undefined,
        minTemperature: measurement.minTemperature,
        temperature: measurement.temperature,
        weatherCondition: measurement.weatherCondition
      }

      // Don't show count outside of the measuring lifetime
      if (mainDate.isBefore(location.metadata.earliestMeasurementAt)) {
        point.pedestriansCount = undefined
      }

      if (filter.viewType === 'compare') {
        const compareDate = dayjs(compareMeasurement.timestamp).tz(
          DatetimeHelpers.zoneFromTimestamp(compareMeasurement.timestamp)
        )
        point.compareTimestamp = compareMeasurement.timestamp
        point.comparePedestriansCount = compareMeasurement.pedestriansCount
        point.compareTemperature = compareMeasurement.temperature
        point.compareWeatherCondition = compareMeasurement.weatherCondition

        if (filter.compareType === 'timerange') {
          sums.comparePedestriansCount += compareMeasurement.pedestriansCount

          // don't show compare count outside of the measuring lifetime
          if (
            compareDate.isAfter(compareLocation.metadata.latestMeasurementAt) ||
            compareDate.isBefore(compareLocation.metadata.earliestMeasurementAt)
          ) {
            point.comparePedestriansCount = undefined
          }
        }

        if (filter.compareType === 'average') {
          if (mainDate.isBefore(location.metadata.earliestMeasurementAt)) {
            // do not show averages before we have any measurements
            point.comparePedestriansCount = null
          } else {
            const dayjsDate = dayjs(point.timestamp).tz(
              DatetimeHelpers.zoneFromTimestamp(point.timestamp)
            )
            const day = dayjsDate.day()
            const hour = dayjsDate.hour()

            // for now, we only have averages for hourly and daily measurements
            const average =
              filter.resolution === 'hour'
                ? get(averagesPerHour, [day, hour, 'avgCount'], null)
                : filter.resolution === 'day'
                ? get(averagesPerDay, [day, 'avgCount'], null)
                : null

            sums.comparePedestriansCount += average
            point.comparePedestriansCount = average
          }
        }
      } else if (filter.viewType === 'details') {
        const details = measurement.details
        let adult, child, ltr, rtl

        // pedestriansCount === undefined means that the point should not be
        // visible, thus the details don't need to be calculated.
        if (point.pedestriansCount !== undefined) {
          adult = get(details, 'adultPedestriansCount', 0)
          child = get(details, 'childPedestriansCount', 0)
          ltr = get(details, 'ltrPedestriansCount', 0)
          rtl = get(details, 'rtlPedestriansCount', 0)
        }

        sums.details.adult += adult ?? 0
        sums.details.child += child ?? 0
        sums.details.ltr += ltr ?? 0
        sums.details.rtl += rtl ?? 0

        point.detailsPedestriansCount = { adult, child, ltr, rtl }
      } else if (filter.viewType === 'zone') {
        const measurementDetailsZones = measurement.details?.zones || []
        zones.push(measurementDetailsZones)
        const zonesMap = new Map()
        // pedestriansCount === undefined means that the point should not be
        // visible, thus the details don't need to be calculated.
        if (
          point.pedestriansCount !== undefined &&
          measurementDetailsZones.length > 0
        ) {
          measurementDetailsZones.map(zone => {
            zonesMap.set(zone.id, {
              pedestriansCount: zone.pedestriansCount,
              ltr: zone.ltrPedestriansCount,
              rtl: zone.rtlPedestriansCount
            })
          })
        }
        point.zones = zonesMap
      }

      return point
    })

    sums.zones = map(groupBy(flatten(zones), 'id'), (objs, key) => ({
      id: Number(key),
      pedestriansCount: sumBy(objs, 'pedestriansCount'),
      ltr: sumBy(objs, 'ltrPedestriansCount'),
      rtl: sumBy(objs, 'rtlPedestriansCount')
    }))

    return { plot, sums }
  }, [measurements, filter.viewType, location])

  // combine measurements form main location and 1..N compare locations to one object
  const multiLocationTransformerResult = React.useMemo(() => {
    if (!compareLocations) {
      return { plot: [], sums: {} }
    }

    const normalizeMeasurementTimestamps = (
      referenceLocation,
      comparedLocations
    ) => {
      const referenceMeasurementsLength = referenceLocation.measurements.length

      comparedLocations.forEach(location => {
        if (location.measurements.length + 1 === referenceMeasurementsLength) {
          PlotHelpers.addAdditionalHourToMeasurements(
            referenceLocation.measurements,
            location.measurements
          )
        }

        if (referenceMeasurementsLength + 1 === location.measurements.length) {
          PlotHelpers.removeAdditionalHourFromMeasurements(
            referenceLocation.measurements,
            location.measurements
          )
        }
      })

      return [...comparedLocations]
    }

    const buildChartKeys = locations => {
      const chartKeys = new Set()

      locations.forEach(location => {
        chartKeys.add(`pedestriansFor${location.id}`)
      })
      return Array.from(chartKeys)
    }

    const normalizedCompareLocations = normalizeMeasurementTimestamps(
      compareLocations[0],
      compareLocations
    )

    const chartKeys = buildChartKeys(normalizedCompareLocations)

    const plot = transformLocationsToLineChartData(
      normalizedCompareLocations,
      chartKeys
    )

    return { plot, chartKeys }
  }, [compareLocations])

  const stackedChartKeys = React.useMemo(() => {
    // left-padding the array to a constant size helps the graph to animate
    // smoother between different views.
    let keys = [null, 'pedestriansCount']

    if (filter.viewType === 'details' && filter.detailsType === 'height') {
      keys = ['detailsPedestriansCount.child', 'detailsPedestriansCount.adult']
    }

    if (filter.viewType === 'details' && filter.detailsType === 'direction') {
      keys = ['detailsPedestriansCount.rtl', 'detailsPedestriansCount.ltr']
    }

    if (filter.viewType === 'zone') {
      if (filter.zone !== 'all') {
        keys = showZoneDetails
          ? [
              [Number(filter.zone), 'rtl'],
              [Number(filter.zone), 'ltr']
            ]
          : [[Number(filter.zone), 'pedestriansCount']]
      } else {
        const zonesAndKeys = location.metadata.zones.map(zone => [
          Number(zone),
          'pedestriansCount'
        ])
        keys = zonesAndKeys
      }
    }

    if (
      filter.viewType === 'compare' &&
      filter.compareType === 'multiLocation' &&
      compareLocations?.length > 0
    ) {
      keys = multiLocationTransformerResult.chartKeys
    }

    if (filter.objectType === 'VEHICLES') {
      keys = ['vehiclesCount']
    }

    return keys
  }, [
    filter.viewType,
    filter.detailsType,
    filter.compareType,
    filter.objectType,
    filter.zone,
    compareLocations,
    showZoneDetails,
    location.metadata.zones,
    multiLocationTransformerResult.chartKeys
  ])

  // store the result if it's viewable. If the next result isn't viewable, e.g.
  // because it's still fetching, we can use this last result as a fallback.
  if (transformerResult.plot.length > 0) {
    fallbackTransformerResult.current = transformerResult
  }

  const plotIsViewable = transformerResult.plot.length > 0
  const hasFallbackPlot = fallbackTransformerResult.current.plot.length > 0
  const shouldUseFallbackPlot = !plotIsViewable && hasFallbackPlot

  let plot = shouldUseFallbackPlot
    ? fallbackTransformerResult.current.plot
    : transformerResult.plot
  let sums = shouldUseFallbackPlot
    ? fallbackTransformerResult.current.sums
    : transformerResult.sums

  const isMultiLocationComparisonModeActive =
    filter.viewType === 'compare' && filter.compareType === 'multiLocation'

  if (isMultiLocationComparisonModeActive) {
    plot = multiLocationTransformerResult.plot
    sums = multiLocationTransformerResult.sums
  }

  return <context.Provider value={{ plot, sums, stackedChartKeys }} {...rest} />
}
