import React from 'react'
import styled from '@emotion/styled'

import I18n, { Trans } from '../i18n'

import Button from '../components/Button'
import Headline from '../components/Headline'
import Section from '../components/Section'

function ApiIntro() {
  return (
    <Section>
      <Section.Wrapper>
        <Headline center>{I18n.t('pages.developer.top.headline')}</Headline>
        <Subline>{I18n.t('pages.developer.top.subline')}</Subline>
        <Content>
          <Text>
            <Trans id="pages.developer.top.content.text_left" />
          </Text>
          <Text>
            <Trans id="pages.developer.top.content.text_right" />{' '}
            <a
              href="https://hystreet.com/apidocs"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://hystreet.com/apidocs
            </a>
          </Text>
        </Content>
        <ButtonContainer center>
          <Button.Link href="/contact" css={{ margin: '1rem' }}>
            {I18n.t('pages.developer.top.button_text.contact')}
          </Button.Link>
          <Button.Link
            outline
            target="_blank"
            href="https://hystreet.com/apidocs"
            rel="noopener noreferrer"
            css={{ margin: '1rem' }}
          >
            {I18n.t('pages.developer.api.documentation.button_text')}
          </Button.Link>
        </ButtonContainer>
      </Section.Wrapper>
    </Section>
  )
}

const Subline = styled.h3`
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
  margin-bottom: 2rem;

  ${props => props.theme.breakpoints.phone} {
    font-size: 1.3rem;
    text-align: left;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${props => props.theme.breakpoints.tabletUp} {
    flex-direction: row;
  }
`

const Text = styled.div`
  line-height: 1.5;
  padding: 1rem 1.75rem 0;
  margin-bottom: 1.5rem;
  width: 100%;
  ${props => props.theme.breakpoints.tabletUp} {
    width: calc(50% - 1.75rem);
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 2rem;

  ${props => props.theme.breakpoints.tabletUp} {
    flex-direction: row;
  }
`

export default ApiIntro
